import React from 'react'
import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import MetaHeader from '../components/MetaHeader'

const SubscribeSuccess = () => (
  <Layout>
    <MetaHeader title="Success" hidden />

    <PageWrapper>
      <section>
        <h1>Subscription Successful</h1>
        <p>Thank you for subscribing to our newsletter!</p>
      </section>
    </PageWrapper>
  </Layout>
)

export default SubscribeSuccess
